<template>
  <form class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <h4 class="mb-4">ข้อมูลผู้ใช้น้ำ</h4>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อสมาชิก</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="name" v-model="member.M04"/>
                <span class="text-danger text-sm" v-show="errors.has('name')">กรุณากรอกชื่อ-นามสกุลสมาชิก</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขที่บัตรประชาชน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.M08"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เบอร์โทรศัพท์</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.M07"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>บ้านเลขที่</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="addressNo" v-model="member.M05"/>
                <span class="text-danger text-sm" v-show="errors.has('addressNo')">กรุณากรอกบ้านเลขที่</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ที่อยู่</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="villageNo" v-model="member.M06"/>
                <span class="text-danger text-sm" v-show="errors.has('villageNo')">กรุณากรอกหมู่</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ซอย</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" name="soi" v-model="member.soi"/>
                <span class="text-danger text-sm" v-show="errors.has('soi')">กรุณากรอกซอย</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ถนน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" name="road" v-model="member.road"/>
                <span class="text-danger text-sm" v-show="errors.has('road')">กรุณากรอกถนน</span>
              </div>
            </div>
          </div>
        </div>
        <h4 class="mt-6 mb-5">เกี่ยวกับมิเตอร์และมาตรวัด</h4>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>หน่วยมิเตอร์ล่าสุด</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="number" class="w-full" v-validate="'required'" name="lastUnitMeter" v-model="member.M09"/>
                <span class="text-danger text-sm" v-show="errors.has('lastUnitMeter')">กรุณากรอกหน่วยมิเตอร์ล่าสุด</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ขนาดมิเตอร์</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="meters" @input="setUseWaterRate" v-validate="'required'" name="meter" :clearable="false" v-model="selectedMeter" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('meter')">กรุณาเลือกขนาดมิเตอร์</span>
              </div>
            </div>
          </div>
          <div class="mt-6 mb-5 vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>หลักหน้ามาตรวัด (หลัก)</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="number" class="w-full" v-validate="'required'" name="useWaterRate" v-model="member.meter_size"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>อัตราค่าธรรมเนียมใช้น้ำ (บาท) {{member.M11}}</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input class="w-full" v-validate="'required'" name="useWaterRate" v-model="member.M11" readonly/>
                <span class="text-danger text-sm" v-show="errors.has('useWaterRate')">กรุณากรอกอัตราค่าธรรมเนียมใช้น้ำ</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ประเภทผู้ใช้น้ำ (สูตรค่าน้ำ)</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <v-select v-validate="'required'" name="rate" :options="rates" :clearable="false" v-model="selectedRate" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('rate')">กรุณาเลือกประเภทผู้ใช้น้ำ</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>กลุ่ม/หน่วยงานที่ใช้น้ำ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <v-select @input="setGroup" :options="groups" v-validate="'required'" name="groups" :clearable="false" v-model="selectedGroup" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('groups')">กรุณาเลือกกลุ่มหรือหน่วยงานที่ใช้น้ำำ</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขประจำมิเตอร์</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="numberOfMeter" v-model="meterNumber"/>
                <span class="text-danger text-sm" v-show="errors.has('numberOfMeter')">กรุณากรอกเลขประจำมิเตอร์</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>พื้นที่จัดเก็บ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setArea" :options="areas" :value="areas" v-validate="'required'" name="area" :clearable="false" v-model="selectedArea" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('area')">กรุณาเลือกพื้นที่จัดเก็บ</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เส้นทางจัดเก็บ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setRoute" :options="routes" :value="areas" v-validate="'required'" name="root" :clearable="false" v-model="selectedRoute" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('root')">กรุณาเลือกเส้นทางจัดเก็บ</span>
              </div>
            </div>
          </div>
        </div>
        <h4 class="mt-6 mb-5">สถานะและค่าธรรมเนียม</h4>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/5 w-full">
            <span>วันที่ขอใช้น้ำ</span>
            <span class="text-red align-text-bottom">*</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <datepicker v-validate="'required'" name="dateUseWater" :language="languages[language]" format="dd MMMM yyyy" v-model="member.M14"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('dateUseWater')">กรุณาเลือกวันที่ขอใช้น้ำ</span>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ค่าจัดเก็บขยะมูลฝอย (บาท)</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-model="member.M15"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ค่าธรรมเนียมบำบัดน้ำเสีย (บาท/%)</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-model="member.M16"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วิธีการชำระเงิน</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setPayBy" v-validate="'required'" name="payBy" :options="paybys" :clearable="false" v-model="selectedPayBy" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('payBy')">กรุณาเลือกวิธีการชำระเงิน</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>เลขที่บัญชี (ผ่านบัญชีธนาคาร)</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input class="w-full" v-model="member.M18" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อธนาคาร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.M19" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>สาขา</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.M20" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ค่าธรรมเนียม</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-model="member.M21" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ประเภทผู้ได้ส่วนลด</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setDiscountM22" v-validate="'required'" name="typeOfDiscount" :options="discount" :clearable="false" v-model="selectedDiscount" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('typeOfDiscount')">กรุณาเลือกประเภทผู้ได้ส่วนลด</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ส่วนลดที่ได้</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="discountValue" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วันที่ตัดน้ำ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="cutOffDate"></datepicker>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วันที่ประสานมาตร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="mergeMeterDate"></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/5 w-full">
            <span>ต้องการออก Vat หรือไม่</span>
          </div>
          <div class="vx-col sm:w-3/5 w-full">
            <vs-checkbox class="inline-flex" v-model="selectedVat">ต้องการ Vat</vs-checkbox>
          </div>
        </div>
        <h4 class="mt-6 mb-5">สถานะการใช้น้ำ/ถูกงดใช้น้ำ</h4>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>สถานะการใช้งาน</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select v-validate="'required'" @input="setDateStatus" name="status" :options="unuses" :clearable="false" v-model="selectedUnuse" :disabled="disabledStatus" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('status')">กรุณาเลือกสถานะการใช้งาน</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-2/5 w-full">
                <span>วันที่ยกเลิกหรืองดจ่ายน้ำ</span>
              </div>
              <div class="vx-col sm:w-3/5 w-full">
                <datepicker name="cancelOrPayment" :language="languages[language]" format="dd-MM-yyyy" v-model="member.M24" :disabled="disabledDateStatus"></datepicker>
                <span class="text-danger text-sm" v-show="errors.has('cancelOrPayment')">กรุณาเลือกวันที่ยกเลิกหรืองดจ่ายน้ำ</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/5 w-full">
            <span>หมายเหตุ</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input type="ะำปะ" class="w-full" v-model="member.remark"/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/6 w-full">
            <span>แจ้งปัญหา</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <div class="vx-row mb-5">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="1">1.มาตรอยู่ในบ้าน</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="2">2.มาตรชำรุด</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="3">3.น้ำรั่วภายในบ้าน</vs-checkbox>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="4">4.หน้าปัทม์มองไม่เห็น</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="5">5.มีสิ่งกีดขวาง</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <div style="display: table-cell; width: 80px">
                  <vs-checkbox v-model="checkBoxIssue" vs-value="6">6.อื่นๆ</vs-checkbox>
                </div>
                <div style="display: table-cell;" v-if="disabledOtherIssues">
                  <vs-input class="w-full" v-model="otherIssues" :disabled="disabledOtherIssues"/>
                </div>
                <div style="display: table-cell;" v-else>
                  <vs-input type="text" class="w-full" v-validate="'required'" name="otherIssues" v-model="otherIssues" />
                  <span class="text-danger text-sm" v-show="errors.has('otherIssues')">กรุณากรอกแจ้งปัญหาอื่นๆ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col sm:w-1/2 w-full text-right">
            <vs-button color="danger" type="filled" class="mb-2" @click="$router.push('/user')">ยกเลิก</vs-button>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-button class="mb-2" @click="submitData">บันทึก</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </form>
</template>
<script>
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import moduleGroup from "@/store/group/moduleGroup.js";
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import moduleRoute from "@/store/route/moduleRoute.js";
  import moduleMeter from "@/store/meter/moduleMeter.js";
  import moduleDiscount from "@/store/discount/moduleDiscount.js";
  import modulePayby from "@/store/payby/modulePayby.js";
  import moduleUnuse from "@/store/unuse/moduleUnuse.js";
  import moduleRate from "@/store/rate/moduleRate.js";
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
  import * as lang from 'vuejs-datepicker/src/locale';

  export default {
    components: {
      Datepicker,
      vSelect
    },
    data() {
      return {
        language: 'th',
        languages: lang,
        isBusy: false,
        date: null,
        member: {},
        selectedVat: false,
        disabledPayBy: false,
        disabledStatus: true,
        disabledDateStatus: false,
        meterNumber: '',
        discountValue: '',
        selectedUnuse: {label: '', value: ''},
        selectedArea: {label: '', value: ''},
        selectedRoute: {label: '', value: ''},
        selectedMeter: {label: '', value: '', data: ''},
        selectedRate: {label: '', value: ''},
        selectedGroup: {label: '', value: ''},
        selectedDiscount: {label: '', value: '', data: ''},
        selectedStatus: {label: '', value: ''},
        selectedPayBy: {label: '', value: ''},
        roleFilter: {label: '1', value: '1'},
        roleOptions: [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {
          label: '4',
          value: '4'
        },],
        checkBoxIssue: [],
        disabledOtherIssues: true,
        otherIssues: '',
        cutOffDate: '',
        mergeMeterDate: ''
      }
    },
    computed: {
      areas() {
        let options = [];
        this.$store.state.area.items.forEach((item) => {
          options.push({
            'label': item.A02,
            'value': item.A01
          });
        });
        return options;
      },
      routes() {
        let options = [];
        this.$store.state.route.items.forEach((item) => {
          options.push({
            'label': item.R02,
            'value': item.R01
          });
        });
        return options;
      },
      meters() {
        let options = [];
        this.$store.state.meter.items.forEach((item) => {
          options.push({
            'label': item.MS02,
            'value': item.MS01,
            'data': item
          });
        });
        return options;
      },
      discount() {
        let options = [];
        this.$store.state.discount.items.forEach((item) => {
          options.push({
            'label': item.MR02,
            'value': item.MR01,
            'data': item
          });
        });
        return options;
      },
      paybys() {
        let options = [];
        this.$store.state.payby.items.forEach((item) => {
          options.push({
            'label': item.pb02,
            'value': item.pb01
          });
        });
        return options;
      },
      unuses() {
        let options = [];
        this.$store.state.unuse.items.forEach((item) => {
          options.push({
            'label': item.U02,
            'value': item.U01
          });
        });
        return options;
      },
      rates() {
        let options = [];
        this.$store.state.rate.names.forEach((item) => {
          options.push({
            'label': item.metertype_name,
            'value': item.metertype_id
          });
        });
        return options;
      },
      groups() {
        let options = [];
        this.$store.state.group.items.forEach((item) => {
          options.push({
            'label': item.MG02,
            'value': item.MG01
          });
        });
        return options;
      },
    },
    watch: {
      checkBoxIssue(val) {
        let flag = false
        if(val.length !== 0) {
          for(let i = 0; i < val.length; i++) {
            if(val[i] === '6') {
              flag = true
            }
          }
        }
        if(flag) {
          this.disabledOtherIssues = false
        } else {
          this.disabledOtherIssues = true
          this.otherIssues = ''
        }
      }
    },
    methods: {
      async init(){
        this.member = await this.$store.state.member.item;
        if (this.$store.state.area.items.length > 0 && this.member.M02) {
          this.setArea(this.member.M03);
        }
        if (this.$store.state.route.items.length > 0 && this.member.M02) {
          this.setRoute(this.member.M02);
        }
        if (this.$store.state.meter.items.length > 0) {
          this.setMeter(this.member.M10);
        }
        if (this.$store.state.rate.names.length > 0) {
          this.setRate(this.member.M12);
        }
        if (this.$store.state.group.items.length > 0) {
          this.setGroup(this.member.M13);
        }
        if (this.$store.state.payby.items.length > 0) {
          this.setPaybyData(this.member.M17);
        }
        if (this.$store.state.discount.items.length > 0) {
          this.setDiscount(this.member.M22);
        }
        if (this.$store.state.unuse.items.length > 0) {
          this.setUnuse(this.member.M23);
        }
        if (this.member.M26 === 'True') {
          this.selectedVat = true;
        } else {
          this.selectedVat = false;
        }
        if (this.member.issues !== undefined && this.member.issues.length !== 0) {
          let array = []
          for(let i = 0; i < this.member.issues.length; i++) {
            array.push(this.member.issues.toString())
          }
          this.checkBoxIssue = array
        }
        this.meterNumber = this.member.M27;
        if (this.member.cutOffDate !== undefined) {
          this.cutOffDate = this.member.cutOffDate
        }
        if (this.member.mergeMeterDate !== undefined) {
          this.mergeMeterDate = this.member.mergeMeterDate
        }
        let bill = await this.$store.state.billConfigs.item;
        if (bill.bookListCount === 0) {
          this.disabledStatus = false;
          if (this.member.M23 !== '01') {
            this.disabledDateStatus = false;
          }
        }
        this.$vs.loading.close();
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.member.M02 = this.selectedArea.value;
            this.member.M03 = this.selectedRoute.value;
            this.member.M10 = this.selectedMeter.value;
            this.member.M11 = parseFloat(this.member.M11);
            this.member.M12 = this.selectedRate.value;
            this.member.M13 = this.selectedGroup.value;
            this.member.M15 = parseFloat(this.member.M15);
            this.member.M17 = this.selectedPayBy.value;
            this.member.M22 = this.selectedDiscount.value;
            this.member.M23 = this.selectedUnuse.value;
            this.member.M25 = 0.0;
            this.member.M27 = this.meterNumber;
            this.member.M32 = this.selectedUnuse.value;
            if (this.selectedVat === true) {
              this.member.M26 = 'True';
            } else {
              this.member.M26 = 'False';
            }
            if (this.selectedUnuse.value === '01') {
              this.member.M24 = '';
            }
            if(this.checkBoxIssue.length !== 0) {
              let array = []
              let countOther = false
              for(let i = 0; i < this.checkBoxIssue.length; i++) {
                array.push(parseInt(this.checkBoxIssue[i]))
                if(this.checkBoxIssue[i] === '6') {
                  countOther = true
                }
              }
              this.member.issues = array
              if(!countOther) {
                this.member.otherIssues = ''
              } else {
                this.member.otherIssues = this.otherIssues
              }
            } else {
              this.member.issues = []
              this.member.otherIssues = ''
            }
            this.member.cutOffDate = this.cutOffDate
            this.member.mergeMeterDate = this.mergeMeterDate
            this.$store.dispatch("member/updateItem", this.member).then((res) => {
             if (res.status === 200 || res[0].status === 200) {
               this.successDialog();
             } else {
               this.failDialog();
             }
            }).catch((error) => {
              this.failDialog();
            });
          }else{
            this.warningDialog();
          }
        })
      },
      setDateStatus(item) {
        if (item.value === '01') {
          this.disabledDateStatus = true;
          this.member.M24 = '';
        } else {
          this.disabledDateStatus = false;
          this.member.M24 = new Date();
        }
      },
      setPayBy(item) {
        if (item.value === '01') {
          this.member.M18 = '';
          this.member.M19 = '';
          this.member.M20 = '';
          this.member.M21 = '';
          this.disabledPayBy = true;
        } else {
          this.member.M18 = '';
          this.member.M19 = '';
          this.member.M20 = '';
          this.member.M21 = '';
          this.disabledPayBy = false;
        }
      },
      setPaybyData(item) {
        let data = {label: '', value: ''};
        this.$store.state.payby.items.forEach((payby) => {
          if (item == payby.pb01) {
            data = {
              'label': payby.pb02,
              'value': payby.pb01
            };
          }
        });
        this.selectedPayBy = data;
        this.setPayBy(this.selectedPayBy);
      },
      setGroup(item) {
        let data = {label: '', value: ''};
        let groupItem;
        if (item === undefined || item.value === undefined) {
          groupItem = item;
        } else {
          groupItem = item.value;
        }
        this.$store.state.group.items.forEach((group) => {
          if (groupItem == group.MG01) {
            data = {
              'label': group.MG02,
              'value': group.MG01
            };
          }
        });
        this.selectedGroup = data;
      },
      setRoute(item) {
        let data = {label: '', value: ''};
        let routeItem;
        if (item.value === undefined) {
          routeItem = item;
        } else {
          routeItem = item.value;
        }
        this.$store.state.route.items.forEach((route) => {
          if (routeItem == route.R01) {
            data = {
              'label': route.R02,
              'value': route.R01
            };
          }
        });
        this.selectedRoute = data;
      },
      setArea(item) {
        let data = {label: '', value: ''};
        let areaItem;
        if (item.value === undefined) {
          areaItem = item;
        } else {
          areaItem = item.value;
        }
        this.$store.state.area.items.forEach((area) => {
          if (areaItem === area.A01) {
            data = {
              'label': area.A02,
              'value': area.A01
            };
          }
        });
        this.selectedArea = data;
      },
      setMeter(item) {
        let data = {label: '', value: '', data: ''};
        this.$store.state.meter.items.forEach((meter) => {
          if (item === meter.MS01) {
            data = {
              'label': meter.MS02,
              'value': meter.MS01,
              'data': meter
            };
          }
        });
        this.selectedMeter = data;
        this.setUseWaterRate(this.selectedMeter);
      },
      setDiscount(item) {
        let data = {label: '', value: '', data: ''};
        this.$store.state.discount.items.forEach((discount) => {
          if (item === discount.MR01) {
            data = {
              'label': discount.MR02,
              'value': discount.MR01,
              'data': discount
            };
          }
        });
        this.selectedDiscount = data;
        this.setDiscountM22(this.selectedDiscount);
      },
      setUnuse(item) {
        let data = {label: '', value: ''};
        this.$store.state.unuse.items.forEach((unuse) => {
          if (item === unuse.U01) {
            data = {
              'label': unuse.U02,
              'value': unuse.U01
            };
          }
        });
        this.selectedUnuse = data;
      },
      setRate(item) {
        let data = {label: '', value: ''};
        this.$store.state.rate.names.forEach((rate) => {
          if (item === rate.metertype_id) {
            data = {
              'label': rate.metertype_name,
              'value': rate.metertype_id
            };
          }
        });
        this.selectedRate = data;
      },
      setUseWaterRate(item) {
        this.member.M11 = item.data.MS03;
      },
      setDiscountM22(item) {

        this.discountValue = item.data.MR03;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'กรุณากรอกเข้อมูลให้ครบ',
          acceptText: 'ปิด'
        })
      },
      route(){
        this.$router.push('/user').catch(() => {})
      },
      failDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: 'แก้ไขผู้ใช้น้ำไม่สำเร็จ',
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'แก้ไขผู้ใช้น้ำสำเร็จ',
          acceptText: 'ปิด',
          accept: this.route
        })
      }
    },
    async created() {
      this.$vs.loading()
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      if (!moduleRoute.isRegistered) {
        this.$store.registerModule('route', moduleRoute);
        moduleRoute.isRegistered = true
      }
      if (!moduleMeter.isRegistered) {
        this.$store.registerModule('meter', moduleMeter);
        moduleMeter.isRegistered = true
      }
      if (!moduleDiscount.isRegistered) {
        this.$store.registerModule('discount', moduleDiscount);
        moduleDiscount.isRegistered = true
      }
      if (!modulePayby.isRegistered) {
        this.$store.registerModule('payby', modulePayby);
        modulePayby.isRegistered = true
      }
      if (!moduleUnuse.isRegistered) {
        this.$store.registerModule('unuse', moduleUnuse);
        moduleUnuse.isRegistered = true
      }
      if (!moduleRate.isRegistered) {
        this.$store.registerModule('rate', moduleRate);
        moduleRate.isRegistered = true
      }
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember)
        moduleMember.isRegistered = true
      }
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig)
        moduleBillConfig.isRegistered = true
      }
      if(!moduleGroup.isRegistered) {
        this.$store.registerModule('group', moduleGroup)
        moduleGroup.isRegistered = true
      }
      await this.$store.dispatch("group/fetchDataListItems");
      await this.$store.dispatch("billConfigs/fetchDataItem");
      await this.$store.dispatch("rate/fetchNameListItems");
      await this.$store.dispatch("unuse/fetchDataListItems");
      await this.$store.dispatch("payby/fetchDataListItems");
      await this.$store.dispatch("discount/fetchDataListItems");
      await this.$store.dispatch("meter/fetchDataListItems");
      await this.$store.dispatch("route/fetchDataListItems");
      await this.$store.dispatch("area/fetchDataListItems");
      await this.$store.dispatch("member/fetchDataItem", this.$route.params.userId);
      await this.init();
    }
  }
</script>
